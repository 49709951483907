import { queryRun as queryRunDomain } from "@fscrypto/domain";
import { useEntity, useOptionalStore } from "@fscrypto/state-management";
import { queryFactory } from "../state/query";

interface UseQueryParamsProps {
  queryId: string;
}

export const useQueryRunResult = ({ queryId }: UseQueryParamsProps) => {
  // Fetch the query entity
  const entity = useEntity(queryFactory, queryId);

  const resultStore = entity?.latestRun;

  // Get the result from the store
  const result = useOptionalStore(resultStore);

  // Determine if the query is running or canceled
  const isRunning = result ? queryRunDomain.isRunning(result?.status) : false;
  const isCanceled = result ? queryRunDomain.isCanceled(result?.status) : false;
  return {
    result,
    isRunning,
    isCanceled,
    status: result?.status,
  };
};
