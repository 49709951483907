import { queryRun } from "@fscrypto/domain";
import { OnChangeFn, SortingState, Table, TableState } from "@fscrypto/table";
import { memo, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { getDateTimeDurationFromDates } from "~/utils/helpers";
import { generateTableColumns } from "./generate-table-columns";
import { QueryTableFooter } from "./query-table-footer";
import { TableId } from "../../state/query-table-state";
import clsx from "clsx";
import { useLocation } from "@remix-run/react";

interface TableProps {
  results: queryRun.QueryRunResult;
  dashboardView?: boolean;
  previewView?: boolean;
  isTransposed?: boolean;
  toggleTransposed?: () => void;
  onSortingChange?: OnChangeFn<SortingState>;
  sorting?: SortingState;
  tableId: TableId;
  filter?: string;
  onFilterChange?: (value: string) => void;
  pageIndex?: number;
  onPageIndexChange?: (newPage: number) => void;
}

const ResultsTableComponent = ({
  results,
  isTransposed = false,
  toggleTransposed,
  tableId,
  dashboardView,
  previewView,
  sorting = [],
  onSortingChange,
  filter,
  onFilterChange,
  pageIndex,
  onPageIndexChange,
}: TableProps) => {
  const columns = useMemo(() => generateTableColumns(results, isTransposed), [results, isTransposed]);
  const startedAt = results.startedAt ? new Date(results.startedAt) : new Date();
  const endedAt = results.endedAt ? new Date(results.endedAt) : new Date();

  const duration = results.startedAt && results.endedAt ? getDateTimeDurationFromDates(startedAt, endedAt) : "";

  return (
    <ViewContainer>
      <ErrorBoundary FallbackComponent={ErrorResults}>
        <Table
          tableId={tableId}
          columns={columns}
          data={results.csvData}
          paginate
          initialPageSize={20}
          dashboardView={dashboardView}
          previewView={previewView}
          transpose={isTransposed}
          onSortingChange={onSortingChange}
          sorting={sorting}
          filter={filter}
          onFilterChange={onFilterChange}
          onPageIndexChange={onPageIndexChange}
          pageIndex={pageIndex}
          customFooter={(table: TableState<any>) => (
            <QueryTableFooter table={table} duration={duration} results={results} tableId={tableId} />
          )}
        />
      </ErrorBoundary>
    </ViewContainer>
  );
};

export const ResultsTable = memo(ResultsTableComponent);

const ErrorResults = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      Something went wrong while rendering your results
    </div>
  );
};

const ViewContainer = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const visView = location.pathname.includes("visualizations");
  return (
    <div
      className={clsx(" w-full max-w-full overflow-hidden", {
        "h-[calc(100%-48px)]": !visView,
        "h-full": visView,
      })}
    >
      {children}
    </div>
  );
};
