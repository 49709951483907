import { QueryRunResult } from "@fscrypto/domain/query-run";
import { createStore, useStore } from "@fscrypto/state-management";
import { Card } from "@fscrypto/ui";
import { useQuery } from "~/features/studio-2/query/state/query";
import { useQueryRunResult } from "../../hooks/useQueryRunResult";
import { useSelectAndRun } from "../../state/query-ephemeral-runs";
import { useTablePreviews } from "../../state/query-table-preview";
import { EmptyQueryRun } from "../query-results-table/empty-query-run";
import { FailedRun } from "../query-results-table/failed-run";
import { QueryResultsTable } from "../query-results-table/query-results-table";
import ActiveSteps from "./active-steps";
import { ResultsPanelHeader } from "./query-results-header";

interface QueryResultsProps {
  queryId: string;
  run?: QueryRunResult;
}
export const QueryResults = ({ queryId, run }: QueryResultsProps) => {
  const query = useQuery(queryId);
  const queryRunId = run?.queryRunId;

  if (!query) return null;

  if (!run) {
    return <EmptyQueryRun />;
  }
  if (run.status === "failed") {
    return <FailedRun message={run.errorMessage} />;
  }
  if (run.status === "canceled") {
    return <FailedRun message="Query was canceled" />;
  }
  if (!run.resultsRetrieved) {
    return <ActiveSteps queryRun={run} cancelQuery={() => query.cancel()} />;
  }
  return <QueryResultsTable queryRun={run} tableId={`query-${queryId}-${queryRunId}`} />;
};

export const QuerySelectAndRunResults = ({ queryId }: { queryId: string }) => {
  const { results, cancelSelectAndRun } = useSelectAndRun(queryId);
  const run = results?.result;
  if (!run) {
    return <EmptyQueryRun />;
  }
  if (run.status === "failed") {
    return <FailedRun message={run.errorMessage} />;
  }
  if (run.status === "canceled") {
    return <FailedRun message="Query was canceled" />;
  }
  if (!run.resultsRetrieved) {
    return <ActiveSteps queryRun={run} cancelQuery={() => cancelSelectAndRun()} />;
  }
  return <QueryResultsTable queryRun={run} tableId={`selectAndRun-${queryId}-${run.queryRunId}`} />;
};

export const PreviewTableResults = ({ table }: { table: string }) => {
  const previewTables = useTablePreviews();
  const preview = previewTables.previews.find((p) => p.table === table);
  if (!preview || !preview.data) return null;
  return <QueryResultsTable queryRun={preview.data} tableId={`preview-${table}`} />;
};

export type QueryResultsContainerStore = {
  activeResults: "query" | "selectAndRun" | string;
};
const queryResultsStore = createStore<QueryResultsContainerStore>({
  activeResults: "query",
});

export const useQueryResultsStore = () => {
  const store = useStore(queryResultsStore);

  const setActiveResults = (activeResults: "query" | "selectAndRun" | string) =>
    queryResultsStore.set({ ...store, activeResults });

  return {
    activeResults: store.activeResults,
    setActiveResults,
  };
};

export const QueryResultsContainer = ({ queryId }: { queryId: string }) => {
  const query = useQuery(queryId);
  const { activeResults } = useQueryResultsStore();
  const { result } = useQueryRunResult({ queryId });
  if (!query) return null;
  return (
    <Card className="h-full" rounded={false}>
      <ResultsPanelHeader queryId={queryId} />
      {activeResults === "query" && <QueryResults queryId={queryId} run={result} />}
      {activeResults === "selectAndRun" && <QuerySelectAndRunResults queryId={queryId} />}
      <PreviewTableResults table={activeResults} />
    </Card>
  );
};
