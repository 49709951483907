import { createStore, useStore } from "@fscrypto/state-management";
import { SortingState, Updater } from "@fscrypto/table";
import { TableState } from "@fscrypto/viz-2";

export const defaultState: TableState = {
  transposed: false,
  sorting: [],
  pageIndex: 0,
  filter: "",
};

type TableVariants = "query" | "selectAndRun" | "preview";

export type TableId = `${TableVariants}-${string}`;

const tableStates = createStore<Record<TableId, TableState>>({});

export const useTableState = (tableId: TableId) => {
  const states = useStore(tableStates);

  return {
    state: states[tableId] || defaultState,
    setState: (state: Partial<TableState>) => {
      const newState = { ...(states[tableId] ?? defaultState), ...state };
      tableStates.set({ ...states, [tableId]: newState });
    },
    setSorting: (sorting: SortingState | Updater<SortingState>) => {
      const newSorting = typeof sorting === "function" ? sorting(states[tableId]?.sorting) : sorting;
      tableStates.set({ ...states, [tableId]: { ...states[tableId], sorting: newSorting } });
    },
  };
};
