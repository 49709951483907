import { queryRun } from "@fscrypto/domain";
import { TableState } from "@fscrypto/table";
import { Input, Text, Tooltip } from "@fscrypto/ui";
import { Button } from "@fscrypto/ui";
import { Database, HardDrive, SearchIcon, Timer } from "lucide-react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Fragment } from "react";
import { useDebouncedCallback } from "use-debounce";
import { getObjectSizeAsString } from "~/utils/helpers";
import { tracking } from "~/utils/tracking";
import { TableId, useTableState } from "../../state/query-table-state";

export const QueryTableFooter = ({
  table,
  results,
  duration,
  tableId,
}: {
  table: TableState<any>;
  results: queryRun.QueryRunResult;
  duration: string;
  tableId: TableId;
}) => {
  return (
    <div className="flex justify-between p-2">
      <Filter tableId={tableId} />
      <PaginationControls table={table} />
      <TableInformation results={results} duration={duration} />
    </div>
  );
};

interface TableInformationProps {
  results: queryRun.QueryRunResult;
  handleTranspose?: () => void;
  duration: string;
}

const TableInformation = ({ results, duration }: TableInformationProps) => {
  return (
    <div className="flex items-center gap-x-3 px-2">
      <Tooltip content="Rows" side="top">
        <div className="flex gap-x-1">
          <Database className="size-4 text-primary/80" />
          <Text size="xs">{results.csvData.length}</Text>
        </div>
      </Tooltip>
      <Tooltip content="Data Size" side="top">
        <div className="flex gap-x-1">
          <HardDrive className="size-4 text-primary/80" />
          <Text size="xs">{getObjectSizeAsString(results.csvData)}</Text>
        </div>
      </Tooltip>
      <Tooltip content="Duration" side="top">
        <div className="flex items-center gap-x-1">
          <Timer className="size-4 text-primary/80" />
          <Text size="sm">{duration}</Text>
        </div>
      </Tooltip>
    </div>
  );
};

const Filter = ({ tableId }: { tableId: TableId }) => {
  const { state, setState } = useTableState(tableId);
  const track = useDebouncedCallback(() => {
    tracking("editor_chart_results_search", "Dashboard Beta");
  }, 750);

  return (
    <div className="flex items-center gap-x-3">
      <Input
        prefix={<SearchIcon />}
        value={state.filter}
        onChange={(e) => {
          setState({ filter: e.target.value });
          track();
        }}
        size="sm"
        placeholder="Search"
      />
    </div>
  );
};

// Function to generate page numbers and ellipses
const generatePageNumbers = (currentPage: number, pageCount: number): (number | string)[] => {
  const pages: (number | string)[] = [];

  const pagingShowLowElipsis = currentPage > 3;
  const pagingShowHighElipsis = currentPage < pageCount - 2;
  const prevPageNumber = currentPage - 1;
  const nextPageNumber = currentPage + 1;

  if (pageCount <= 5) {
    for (let i = 1; i <= pageCount; i++) {
      pages.push(i);
    }
  } else {
    pages.push(1);
    if (pagingShowLowElipsis) pages.push("...");
    if (prevPageNumber > 1 && prevPageNumber < pageCount) pages.push(prevPageNumber);
    if (currentPage !== 1 && currentPage !== pageCount) pages.push(currentPage);
    if (nextPageNumber > 1 && nextPageNumber < pageCount) pages.push(nextPageNumber);
    if (pagingShowHighElipsis) pages.push("...");
    pages.push(pageCount);
  }

  return pages;
};

const PaginationControls = ({ table }: { table: TableState<any> }) => {
  const { pageIndex } = table.getState().pagination;
  const currentPage = pageIndex + 1;

  const pages = generatePageNumbers(currentPage, table.getPageCount());

  const trackPaginationClick = () => tracking("editor_table_pagination_click", "Dashboard Beta");

  return (
    <div className="flex items-center space-x-2">
      <PrevButton
        onClick={() => {
          table.previousPage();
          trackPaginationClick();
        }}
        disabled={!table.getCanPreviousPage()}
      />

      {pages.map((page, index) => (
        <Fragment key={index}>
          {page === "..." ? (
            <span>...</span>
          ) : (
            <Button
              size="sm"
              onClick={() => {
                if (currentPage !== page) {
                  table.setPageIndex((page as number) - 1);
                  trackPaginationClick();
                }
              }}
              variant={currentPage === page ? "outline" : "ghost"}
            >
              {page}
            </Button>
          )}
        </Fragment>
      ))}

      <NextButton
        onClick={() => {
          table.nextPage();
          trackPaginationClick();
        }}
        disabled={!table.getCanNextPage()}
      />
    </div>
  );
};

const NextButton = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => {
  if (disabled) return null;
  return (
    <Button size="sm" onClick={onClick} disabled={disabled} variant={"ghost"}>
      <span>Next</span>
      <ChevronRight className="h-4 w-4" />
    </Button>
  );
};

const PrevButton = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => {
  if (disabled) return null;
  return (
    <Button size="sm" onClick={onClick} disabled={disabled} variant="ghost">
      <span>Previous</span>
      <ChevronLeft className="h-4 w-4" />
    </Button>
  );
};

export default PaginationControls;
