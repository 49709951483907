import { memo } from "react";
import { ResultsTable } from "./results-table";
import { QueryRunResult } from "@fscrypto/domain/query-run";
import { TableId, useTableState } from "../../state/query-table-state";

const QueryResultsTableComponent = ({ queryRun, tableId }: { queryRun: QueryRunResult; tableId: TableId }) => {
  const { state, setState, setSorting } = useTableState(tableId);
  return (
    <ResultsTable
      results={queryRun}
      sorting={state.sorting}
      filter={state.filter}
      isTransposed={state.transposed}
      toggleTransposed={() => setState({ transposed: !state.transposed })}
      onSortingChange={setSorting}
      onFilterChange={(filter) => setState({ filter })}
      tableId={tableId}
      pageIndex={state.pageIndex}
      onPageIndexChange={(pageIndex) => setState({ pageIndex })}
    />
  );
};

export const QueryResultsTable = memo(QueryResultsTableComponent);
