import { Button, HoverCard, Text } from "@fscrypto/ui";
import { CodeIcon, Loader2, WholeWord, X } from "lucide-react";
import { useRef } from "react";
import { useCodeMirror } from "@uiw/react-codemirror";
import clsx from "clsx";
import { sql } from "lang-sql";
import { formatAndReplace } from "~/shared/utils/format-and-replace-statement";
import { light, dark } from "~/shared/utils/code-mirror-themes";
import { useUserStateMachine } from "~/state/machines/user-state/user-state";
import { queryRun } from "@fscrypto/domain";
import { useQueryResultsStore } from "./query-results";
import { useSelectAndRun } from "../../state/query-ephemeral-runs";
import { useQuery } from "../../state/query";

export const SelectionRunTab = ({ queryId }: { queryId: string }) => {
  const { results, removeSelectAndRun } = useSelectAndRun(queryId);
  const { setActiveResults, activeResults } = useQueryResultsStore();
  const query = useQuery(queryId);
  if (!results) return null;
  const isComplete = queryRun.isFinished(results.result.status) || queryRun.isFailed(results.result.status);

  const handleClose = () => {
    removeSelectAndRun();
    setActiveResults("query");
  };

  return (
    <HoverCard.Root openDelay={2000}>
      <HoverCard.Trigger asChild>
        <Button
          size="sm"
          variant={activeResults === "selectAndRun" ? "outline" : "secondary"}
          data-testid="panel-header-results"
          onClick={() => {
            setActiveResults("selectAndRun");
            query?.resizeIfClosed();
          }}
          className={clsx({ "bg-background": activeResults === "selectAndRun" })}
        >
          <WholeWord
            aria-label="add-button"
            className={clsx("mr-2 size-4", {
              "text-primary/80": activeResults === "selectAndRun",
              "text-muted-foreground": activeResults !== "selectAndRun",
            })}
          />
          <Text>Selection Results</Text>
          {isComplete ? (
            <X
              className="text-content mx-2 size-4 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            />
          ) : (
            <Loader2 className="text-content size-3 animate-spin" />
          )}
        </Button>
      </HoverCard.Trigger>
      <HoverCard.Content side="bottom" sideOffset={10} align="center">
        <SelectionPopover selection={results.statement} />
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

export const SelectionPopover = ({ selection }: { selection?: string }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const { theme } = useUserStateMachine();
  let statement = selection;
  try {
    formatAndReplace(statement ?? "");
  } catch (e) {
    // no-op
  }

  useCodeMirror({
    container: editorRef.current,
    value: statement,
    editable: false,
    extensions: [sql()],
    theme: theme === "light" ? light : dark,
  });

  return (
    <div className="flex min-w-[310px] max-w-[600px] flex-col items-center p-2">
      <div className="mb-2 flex items-center">
        <CodeIcon className="mr-1 h-5 w-5 flex-shrink-0 text-gray-600" />
        <h2 className="ml-2 font-medium text-gray-600 dark:text-white">Current Selection</h2>
      </div>
      <div ref={editorRef} className={clsx("cm-theme select-none")} />
    </div>
  );
};
