import { plan } from "@fscrypto/domain";
import { Card } from "@fscrypto/ui";
import { AlertTriangle, Link } from "lucide-react";
import { useCurrentUser } from "~/features/current-user";

export const FailedRun = ({ message }: { message?: string | null }) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <AlertTriangle className="text-warning h-10 w-10" />
      <span className="text-sm">{message ?? "Error Running Query"}</span>
      <UpsellPanel message={message ?? ""} />
    </div>
  );
};

const UpsellPanel = ({ message }: { message: string }) => {
  const { userPlan } = useCurrentUser();
  const isFreePlan = userPlan?.plan ? plan.isFree(userPlan.plan.code) : true;
  const isTimeout = message.includes("Statement reached its statement or warehouse timeout");
  if (!isFreePlan || !isTimeout) return null;
  return (
    <Card variant="info" className="mt-6 p-4" data-testid="api-upsell">
      <span className="text-sm">
        Uh-oh, timeout. Your query has been running for our max of 10 minutes.{" "}
        <Link to="/pricing" className="text-primary/80">
          Upgrade
        </Link>
        {` `} for 2x higher limits and faster results.
      </span>
    </Card>
  );
};
