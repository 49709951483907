import { LogoIcon, Text } from "@fscrypto/ui";

export const EmptyQueryRun = ({ isDashboard }: { isDashboard?: boolean }) => {
  return (
    <div className="bg-surface flex h-full w-full flex-col items-center justify-center gap-y-2 py-6">
      <LogoIcon className="size-10 opacity-40" />
      <Text color="muted" size="sm">
        {isDashboard ? "No Data to display" : "Run a query to Download Data"}
      </Text>
    </div>
  );
};
