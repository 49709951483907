import { Button, Text, Tooltip } from "@fscrypto/ui";
import clsx from "clsx";
import { ChevronRightIcon, Loader2, RotateCcwSquare, Sheet, X } from "lucide-react";
import { DatabaseIcon } from "~/features/app-shell/database-explorer/database-explorer-icon";
import { tracking } from "~/utils/tracking";
import { useQuery } from "../../state/query";
import { TablePreview, useTablePreviews } from "../../state/query-table-preview";
import { TableId, useTableState } from "../../state/query-table-state";
import { QueryResultsContainerStore, useQueryResultsStore } from "./query-results";
import { SelectionRunTab } from "./selection-run-tab";

export const ResultsPanelHeader = ({ queryId }: { queryId: string; isPublic?: boolean }) => {
  return (
    <div className="scrollbar-hide dark:bg-background/90 flex w-full items-center justify-between gap-x-2 bg-[#f8f8f8] p-2">
      <div className="flex flex-1 items-center">
        <QueryRunTab queryId={queryId} />
        <SelectionRunTab queryId={queryId} />
        <PreviewTableTabs />
      </div>
      <TransposeButton queryId={queryId} />
    </div>
  );
};

const QueryRunTab = ({ queryId }: { queryId: string }) => {
  const { setActiveResults, activeResults } = useQueryResultsStore();
  const query = useQuery(queryId);
  return (
    <Button
      size="sm"
      variant={activeResults === "query" ? "outline" : "secondary"}
      data-testid="panel-header-results"
      onClick={() => {
        setActiveResults("query");
        query?.resizeIfClosed();
      }}
      className={clsx({
        "bg-background": activeResults === "query",
      })}
    >
      <Sheet
        aria-label="add-button"
        className={clsx("size-4 mr-2 ", {
          "text-primary/80": activeResults === "query",
          "text-muted-foreground": activeResults !== "query",
        })}
      />
      <Text>Results</Text>
    </Button>
  );
};

const PreviewTableTabs = () => {
  const tablePreviews = useTablePreviews();
  const { setActiveResults, activeResults } = useQueryResultsStore();

  const handleRemove = (table: string) => {
    tablePreviews.removeTablePreview(table);
    setActiveResults("query");
  };
  return (
    <div className="ml-3 flex items-center">
      {tablePreviews.previews.map((preview, i) => {
        return (
          <PreviewTableTab
            preview={preview}
            key={i}
            isActive={activeResults === preview.table}
            onSetActive={() => setActiveResults(preview.table)}
            onRemove={handleRemove}
          />
        );
      })}
    </div>
  );
};

interface TablePreviewTabProps {
  preview: TablePreview;
  isActive: boolean;
  onSetActive?: (str: string) => void;
  onRemove?: (str: string) => void;
}
export const PreviewTableTab = ({ preview, isActive, onSetActive, onRemove }: TablePreviewTabProps) => {
  const { table, schema, database } = convertTablePath(preview.table);
  return (
    <Button
      size="sm"
      variant={isActive ? "outline" : "secondary"}
      className={clsx({ "bg-background": isActive })}
      onClick={() => onSetActive?.(preview.table)}
    >
      <DatabaseIcon name={database!} size="sm" />
      <Text size="xs" color="content">
        {schema}
      </Text>
      <ChevronRightIcon className="size-4 text-contrast" />
      <Text size="xs" color="content">
        {table}
      </Text>
      {preview.data ? (
        <X
          className="text-content/40 size-4 mx-2 cursor-pointer"
          style={{ opacity: isActive ? 1 : 0 }}
          onClick={(e) => {
            if (isActive) {
              e.stopPropagation();
              onRemove?.(preview.table);
            }
          }}
        />
      ) : (
        <Loader2 className="text-content/40 size-4 animate-spin" />
      )}
    </Button>
  );
};

export const convertTablePath = (tablePath: string) => {
  const [database, schema, table] = tablePath.split(".");
  return { database, schema, table };
};

const TransposeButton = ({ queryId }: { queryId: string }) => {
  const { activeResults } = useQueryResultsStore();
  const tableId = createTableId(queryId, activeResults);
  const { state, setState } = useTableState(tableId);
  return (
    <Tooltip content="Transpose" side="bottom">
      <Button
        variant="secondary"
        iconOnly
        size="sm"
        onClick={() => {
          setState({ transposed: !state.transposed });
          tracking("editor_rotate_table", "Dashboard Beta");
        }}
      >
        <RotateCcwSquare className="text-muted-foreground size-4" />
      </Button>
    </Tooltip>
  );
};

const createTableId = (queryId: string, activeResults: QueryResultsContainerStore["activeResults"]): TableId => {
  const tableId =
    activeResults === "query"
      ? `query-${queryId}`
      : activeResults === "selectAndRun"
        ? `selectAndRun-${queryId}`
        : `preview-${activeResults}`;
  return tableId as TableId;
};
